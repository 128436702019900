@font-face {
    font-family: "SourceSansProLight";
    font-weight: 300;
    src: url("../src/fonts/SourceSansPro-Light.woff") format("woff");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
    font-display: swap;
}

@font-face {
    font-family: "SourceSansPro";
    font-weight: 400;
    src: url("../src/fonts/SourceSansPro-Regular.woff") format("woff");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
    font-display: swap;
}

@font-face {
    font-family: "Circular Std Black";
    font-style: normal;
    src: url("../src/fonts/CircularStd-Black.woff") format("woff");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
    font-display: swap;
}

@font-face {
    font-family: "Circular Std Book";
    font-style: normal;
    src: url("../src/fonts/CircularStd-Book.woff") format("woff");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
    font-display: swap;
}

@font-face {
    font-family: "Circular Std Medium";
    font-style: normal;
    src: url("../src/fonts/CircularStd-Medium.woff") format("woff");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
    font-display: swap;
}

html,
body {
    height: 100%;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    font-family: SourceSansPro, sans-serif;
    user-select: auto !important;
    box-sizing: border-box;
    color: #3e485c;
    --white: #ffffff;
    --flipp-color: #2cc0d1;
    --flipp-color-1: rgb(16, 114, 234);
    --flipp-color-2: #3770b8;
    --flip-color-3: rgb(67, 196, 244);
    --gray-0: #d6d6d7;
    --gray-1: #f5f5f5;
    --gray-2: #9fa0a1;
    --gray-3: #71757e;
    --gray-4: #3e485c;
    --gray-5: #e6e6e6;
    --red: #d20000;

}

.container {
    width: 100%;
    max-width: 1500px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    box-sizing: border-box;
}

.flex {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    font-family: inherit;
    color: inherit;
    background: none;
    border: 0;
    padding: 0;
    border-radius: 0;

    &:hover {
        cursor: pointer;
    }
}
